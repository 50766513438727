
import { Injectable } from '@angular/core';
import { Config } from '../app.config';
import { Observable, Observer, Subscription } from 'rxjs';

const SUPPLIER_TEXT_KEY = "GS_SUPPLIER";

@Injectable()
export class SupplierInfoService {

    private supplierString: string;
    private observable: Observable<string>;
    private observer: Observer<string>;
    constructor() {
        this.observable = new Observable(observer => {
            this.observer = observer;
        });
        let s = localStorage.getItem(SUPPLIER_TEXT_KEY);
        if (s) {
            this.supplierString = s;
        }
    }

    setSupplierString(supplierString: string) {
        this.supplierString = supplierString;
        localStorage.setItem(SUPPLIER_TEXT_KEY, supplierString);
        this.observer.next(this.supplierString);
    };

    removeSupplierString() {
        this.supplierString = null;
        localStorage.removeItem(SUPPLIER_TEXT_KEY);
        this.observer.next(null);
    }

    subscribe(next: (supplierString: string) => void): Subscription {
        next(this.supplierString);
        return this.observable.subscribe(next);
    }
}