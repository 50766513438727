<header>
    <div class="header-background"></div>
    <div class="container">
    </div>
</header>
<section>
    <div class="container" style="margin-bottom: 80px;">
        <div class="box solution-detail" [ngClass]="{'loading top':loading}">
            <h1 [innerHtml]="title">
            </h1>
            <div [innerHtml]="content"></div>
        </div>
    </div>
</section>