import { CommonModule } from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';
import { FailtrakComponent } from "./failtrak.component";
import { Config } from "../app.config";

export interface FailtrakConfig {
    baseUrl: string
}

export class FailtrakErrorHandler implements ErrorHandler {

    private baseUrl: string = Config.failtrakPath;

    constructor() {
    }

    handleError(err: any): void {
        if (err instanceof Response) {
            console.log("status")
        }
        try {
            let es = JSON.stringify(err);
            $.post(this.baseUrl + '/ft/e/et', es);
        } catch (error) {

        }
        console.error(err);

    }
}

@NgModule({
    imports: [CommonModule],
    exports: [FailtrakComponent],
    declarations: [FailtrakComponent],
    providers: [{ provide: ErrorHandler, useClass: FailtrakErrorHandler }],
})
export class FailtrakModule {

};
