import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

interface MetadataSettings{
    defaults: { [key: string]: string };
    applicationUrl?: string;
}

@Injectable()
export class MetadataService {
    private metadataSettings: MetadataSettings = {
        defaults: {}
    };
    private isMetadataSet: any;

    constructor(private router: Router,
        @Inject(DOCUMENT) private document: any,
        private titleService: Title, private translateService:TranslateService) {
        this.isMetadataSet = {};
        this.translateService.get('DEFAULT_DESCRIPTION').subscribe((res: string) => {
            this.metadataSettings.defaults['description'] = res;
        })
    }

    setTitle(title: string, override = false): void {
        const ogTitleElement = this.getOrCreateMetaTag('og:title');
        if (!title)
            console.warn('WARNING: No "page title" specified.');
        else
            title = this.removeHtmlEntities(title);

        ogTitleElement.setAttribute('content', title);
        this.titleService.setTitle(title);
    }

    setMetaDescription(value: string): void {
        this.setTag('description', value);
    }

    private strip(html: string): string {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    setTag(tag: string, value: string): void {
        value = this.strip(value);
        if (tag === 'title')
            throw new Error(`Error: Attempt to set ${tag} through 'setTag': 'title' is a reserved tag name. `
                + `Please use 'MetadataService.setTitle' instead.`);

        value = !!value
            ? value
            : !!this.metadataSettings.defaults ? this.metadataSettings.defaults[tag] : '';

        const tagElement = this.getOrCreateMetaTag(tag);

        tagElement.setAttribute('content', tag === 'og:locale' ? value.replace(/-/g, '_') : value);
        this.isMetadataSet[tag] = true;

        if (tag === 'description') {
            const ogDescriptionElement = this.getOrCreateMetaTag('og:description');
            ogDescriptionElement.setAttribute('content', value);
        } else if (tag === 'author') {
            const ogAuthorElement = this.getOrCreateMetaTag('og:author');
            ogAuthorElement.setAttribute('content', value);
        } else if (tag === 'publisher') {
            const ogPublisherElement = this.getOrCreateMetaTag('og:publisher');
            ogPublisherElement.setAttribute('content', value);
        } else if (tag === 'og:locale') {
            const availableLocales = !!this.metadataSettings.defaults
                ? this.metadataSettings.defaults['og:locale:alternate']
                : '';

            this.updateLocales(value, availableLocales);
            this.isMetadataSet['og:locale:alternate'] = true;
        } else if (tag === 'og:locale:alternate') {
            const ogLocaleElement = this.getOrCreateMetaTag('og:locale');
            const currentLocale = ogLocaleElement.getAttribute('content');

            this.updateLocales(currentLocale, value);
            this.isMetadataSet['og:locale'] = true;
        }
    }

    private createMetaTag(name: string): any {
        const el = this.document.createElement('meta');
        el.setAttribute(name.lastIndexOf('og:', 0) === 0 ? 'property' : 'name', name);
        this.document.head.appendChild(el);

        return el;
    }

    private getOrCreateMetaTag(name: string): any {
        let selector = `meta[name="${name}"]`;

        if (name.lastIndexOf('og:', 0) === 0)
            selector = `meta[property="${name}"]`;

        let el = this.document.querySelector(selector);

        if (!el)
            el = this.createMetaTag(name);

        return el;
    }

    private updateLocales(currentLocale: string, availableLocales: any): void {
        if (!currentLocale)
            currentLocale = !!this.metadataSettings.defaults
                ? this.metadataSettings.defaults['og:locale']
                : '';

        const html = this.document.querySelector('html');
        html.setAttribute('lang', currentLocale);

        const selector = `meta[property="og:locale:alternate"]`;
        let elements = this.document.querySelectorAll(selector);

        // fixes "TypeError: Object doesn't support property or method 'forEach'" issue on IE11
        elements = Array.prototype.slice.call(elements);

        elements.forEach((el: any) => {
            this.document.head.removeChild(el);
        });

        if (!!currentLocale && !!availableLocales) {
            availableLocales.split(',')
                .forEach((locale: string) => {
                    if (currentLocale !== locale) {
                        const el = this.createMetaTag('og:locale:alternate');
                        el.setAttribute('content', locale.replace(/-/g, '_'));
                    }
                });
        }
    }

    private updateMetadata(metadata: any, currentUrl: string): void {
        if (metadata.disabled)
            return;

        this.setTitle(metadata.title, metadata.override);

        Object.keys(metadata)
            .forEach(key => {
                let value = metadata[key];

                if (key === 'title' || key === 'override')
                    return;
                else if (key === 'og:locale')
                    value = value.replace(/-/g, '_');
                else if (key === 'og:locale:alternate') {
                    const currentLocale = metadata['og:locale'];
                    this.updateLocales(currentLocale, metadata[key]);

                    return;
                }

                this.setTag(key, value);
            });

        if (!!this.metadataSettings.defaults)
            Object.keys(this.metadataSettings.defaults)
                .forEach(key => {

                    let value = this.metadataSettings.defaults[key];

                    if (key in this.isMetadataSet || key in metadata || key === 'title' || key === 'override')
                        return;
                    else if (key === 'og:locale')
                        value = value.replace(/-/g, '_');
                    else if (key === 'og:locale:alternate') {
                        const currentLocale = metadata['og:locale'];
                        this.updateLocales(currentLocale, this.metadataSettings.defaults[key]);

                        return;
                    }

                    this.setTag(key, value);
                });

        this.setTag('og:url', (this.metadataSettings.applicationUrl || '/') + currentUrl.replace(/\/$/g, ''));
    }

    private removeHtmlEntities(text: string) {
        return text.replace(/&#8211;/g, '-').replace(/&#38;/g, '&').replace(/&#39;/g, '"').replace(/&#47;/g, '/').replace(/&#37;/g, '%').replace(/&#[0-9]+;/g, '');
    }
}