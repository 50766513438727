export * from './admin.service';
import { AdminService } from './admin.service';
export * from './advertisementPackage.service';
import { AdvertisementPackageService } from './advertisementPackage.service';
export * from './articles.service';
import { ArticlesService } from './articles.service';
export * from './campaigns.service';
import { CampaignsService } from './campaigns.service';
export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './fAQ.service';
import { FAQService } from './fAQ.service';
export * from './offers.service';
import { OffersService } from './offers.service';
export * from './pages.service';
import { PagesService } from './pages.service';
export * from './pressRelease.service';
import { PressReleaseService } from './pressRelease.service';
export * from './slides.service';
import { SlidesService } from './slides.service';
export * from './solution.service';
import { SolutionService } from './solution.service';
export * from './supplier.service';
import { SupplierService } from './supplier.service';
export * from './tags.service';
import { TagsService } from './tags.service';
export * from './vacancy.service';
import { VacancyService } from './vacancy.service';
export const APIS = [AdminService, AdvertisementPackageService, ArticlesService, CampaignsService, CategoriesService, FAQService, OffersService, PagesService, PressReleaseService, SlidesService, SolutionService, SupplierService, TagsService, VacancyService];
