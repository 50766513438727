import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetadataService } from '../services/metadata.service';
import { SupplierInfoService } from "../services/supplier-info.service";
import { LocationService } from '../location/location.service';

/**
 * This component renders the start page where the user can enter a registration code: https://easy.gastivo.biz/
 */
@Component({
    selector: 'start',
    templateUrl: 'start.html',
})
export class StartComponent {

    /**
     * The registration code
     */
    code: string;

    /**
     * The component's constructor
     * 
     * @param router The router
     * @param metadataService The meta data service
     * @param translateService The translate service
     * @param supplierInfoService The supplier info service
     */
    constructor(public router: Router, public metadataService: MetadataService, public translateService: TranslateService,
        private supplierInfoService: SupplierInfoService, private locationService: LocationService) {
        metadataService.setTag('robots', 'INDEX,FOLLOW');
        this.translateService.get(["EASY_REGISTRATION_META_TEXT", "EASY_REGISTRATION_META_TITLE"]).subscribe((res: { [key: string]: string }) => {
            this.metadataService.setTitle(res["EASY_REGISTRATION_META_TITLE"]);
            this.metadataService.setMetaDescription(res["EASY_REGISTRATION_META_TEXT"]);
        })
        supplierInfoService.removeSupplierString();
    }

    /**
     * Forward to the easy registratio page 
     */    
    loadCode() {
        if (this.code) {
            this.router.navigate(['go/' + this.code]);
        }
    }
}
