import { Component } from '@angular/core';
import 'jquery';
import { Config } from '../app.config';
import { TranslateService } from '@ngx-translate/core';
import { SupplierInfoService } from "../services/supplier-info.service";
import * as platform from 'platform';
import { LocationService } from '../location/location.service';

/**
 * This component renders the page footer
 */
@Component({
    selector: 'footer',
    templateUrl: 'footer.html'
})
export class FooterComponent {

    /**
     * The current year
     */
    year: number = (new Date()).getFullYear();
    /**
     * The links to the terms an conditions page
     */
    termsLink: string;
    /**
     * The link to the data terms page
     */
    dataTermsLink: string;
    /**
     * The link to the supplier data terms page
     */
    supplierDataTermsLink: string;
    /**
     * The link to the imprint page
     */
    imprintLink: string;
    /**
     * The browser info
     */
    browserInfo: string;
    /**
     * Indicates if the user has supplier specific terms
     */
    hasSupplierTerms: boolean = false;
    /**
     * The current tld
     */
    tld: string
    /**
     * Weather the page is shown for gastivo germany or not
     */
    isDE: boolean
    staticPagePathPrefix: string = ''

    /**
     * The component's constructor
     * 
     * @param translateService The translate service
     * @param supplierInfoService The supplier info service
     */
    constructor(public translateService: TranslateService, private supplierInfoService: SupplierInfoService,
        private locationService: LocationService) {
        this.tld = locationService.getTld()
        this.isDE = locationService.isDomainDE()
        if (!this.isDE) {
          this.staticPagePathPrefix = 's/'
        }
        supplierInfoService.subscribe(s => {
            this.hasSupplierTerms = !!s;
        })

        this.translateService.onLangChange.subscribe(() => {
            this.translateService.get(['STATIC_PAGE_IMPRINT_SLUG', 'STATIC_PAGE_TERMS_SLUG', 'STATIC_PAGE_DATA_TERMS_SLUG', 'STATIC_PAGE_SUPPLIER_DATA_TERMS_SLUG']).subscribe((res: { [key: string]: string }) => {
                this.termsLink = res['STATIC_PAGE_TERMS_SLUG'];
                this.imprintLink = res['STATIC_PAGE_IMPRINT_SLUG'];
                this.dataTermsLink = res['STATIC_PAGE_SUPPLIER_DATA_TERMS_SLUG'];
            })
        })
        if (Config.dev) {
            this.browserInfo = platform.name; // 'IE'
            this.browserInfo += " " + platform.version; // '10.0'
            this.browserInfo += " | " + platform.os;
        }
        
    }

    public openSupportTab() {
        let e = document.getElementsByClassName('LPMcontainer')[0];
        if (e) {
            e.dispatchEvent(new Event('click'));
        }
    }

}
