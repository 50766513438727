/**
 * Userware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Country = 'DE' | 'AT' | 'CH' | 'LI';

export const Country = {
    DE: 'DE' as Country,
    AT: 'AT' as Country,
    CH: 'CH' as Country,
    LI: 'LI' as Country
};

