import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import "jquery";
import { EasyRegistrationService } from "../../../client-user";
import { ErrorService } from "../services/error-handle.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LocationService } from "../location/location.service";

@Component({
  selector: "decline",
  templateUrl: "decline.html",
})
export class DeclineComponent {
  registrationDeclined: boolean = false;
  ready: boolean = false;
  easyRegistrationId: String;
  codeNotFound: boolean = false;
  codeAlreadyUsed: boolean = false;
  supplierName: string = 'Gastivo';
  supplierLogo: SafeResourceUrl;

  constructor(
    private easyRegistrationApi: EasyRegistrationService,
    private errorService: ErrorService,
    private domSantizer: DomSanitizer,
    private locationService: LocationService,
    public route: ActivatedRoute
  ) {
    this.route.params.subscribe((param: any) => {
      let code = param["code"];
      if (code) {
        this.getRegistrationData(code, () => {
          this.declineRegistration(code);
        })
      }
    });
  }

  private getRegistrationData(code: string, callback: () => void) {
    this.easyRegistrationApi.getEasyRegistration(code).subscribe(
      (data) => {
        this.supplierName = data.supplierName;
        this.supplierLogo = this.domSantizer.bypassSecurityTrustResourceUrl(data.supplierLogo);
        callback();
      },
      this.buildErrorHandler()
    )
  }

  private declineRegistration(code: string) {
    this.easyRegistrationApi.declineEasyRegistration(code).subscribe(
      () => {
        this.registrationDeclined = true;
        this.ready = true;
      },
      this.buildErrorHandler()
    );
  }

  private buildErrorHandler(): (error: any) => void {
    return this.errorService.buildSimpleHandler({
      always: () => {
        this.ready = true;
      },
      statusHandles: {
        404: () => {
          this.codeNotFound = true;
        },
        400: () => {
          this.codeAlreadyUsed = true;
        }
      }
    });
  }

  isDomainCH = () => this.locationService.isDomainCH();
}
