export * from './admin.service';
import { AdminService } from './admin.service';
export * from './business.service';
import { BusinessService } from './business.service';
export * from './cRM.service';
import { CRMService } from './cRM.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './easyRegistration.service';
import { EasyRegistrationService } from './easyRegistration.service';
export * from './jMX.service';
import { JMXService } from './jMX.service';
export * from './proxy.service';
import { ProxyService } from './proxy.service';
export * from './public.service';
import { PublicService } from './public.service';
export * from './session.service';
import { SessionService } from './session.service';
export * from './suppliers.service';
import { SuppliersService } from './suppliers.service';
export * from './upload.service';
import { UploadService } from './upload.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AdminService, BusinessService, CRMService, CustomerService, EasyRegistrationService, JMXService, ProxyService, PublicService, SessionService, SuppliersService, UploadService, UserService];
