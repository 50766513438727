
import { Injectable } from '@angular/core';
import { Config } from '../app.config';

/**
 * This component offers methods to set and read cookies
 */
@Injectable()
export class CookieService {

    /**
     * Sets a cookie
     * 
     * @param cname The cookie name
     * @param cvalue The cookie value
     * @param exdays The number of days after which the cookie expires 
     */
    public setCookie(cname: string, cvalue: any, exdays: number) {
        if (cvalue) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            let pVal = JSON.stringify(cvalue);
            if (typeof cvalue == "string") {
                pVal = cvalue;
            }
            let c = cname + "=" + pVal + "; path=/; " + expires;
            if (Config.cookieDomain.length) {
                c += "; domain=" + Config.cookieDomain;
            }
            if (document) {
                document.cookie = c;
            }    
        }
    }

    /**
     * Reads a cookie
     * 
     * @param cname The cookie name
     */
    public getCookie(cname: string) {
        var name = cname + "=";
        if (document) {
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1);

                if (c.indexOf(name) == 0) {
                    let s = c.substring(name.length, c.length);
                    try {
                        return JSON.parse(s);
                    } catch (e) {
                        return s;
                    }
                }

            }
        }
        return "";
    }

    /**
     * Deletes a cookie
     * 
     * @param name The cookie name
     */
    public removeCookie(name: string) {
        this.setCookie(name, "empty", -1);
    }

}