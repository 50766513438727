<nav id="mainNav" class="navbar navbar-default navbar-custom">
    <div class="container">
      <div class="nav navbar-nav navbar-right">
        <div class="nav-entry-language-selection" *ngIf="applicationLanguages.length > 1">
          <div class="language-selection" (click)="openLanguageSelector()">
            <div class="language-selection-button">
                <svg class="language-icon" role="img" xmlns="http://www.w3.org/2000/svg" width="17px" viewBox="0 0 24 24" aria-labelledby="languageIconTitle" stroke="#38414d" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" color="#38414d">
                    <title id="languageIconTitle">Language</title>
                    <circle cx="12" cy="12" r="10" />
                    <path stroke-linecap="round" d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z" />
                    <path stroke-linecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15" />
                </svg>
                <a class="language-selector">{{currentApplicationLanguage.shortName}}</a>
            </div>
            <div *ngIf="showLanguageSelection" class="language-options">
                <a *ngFor="let language of applicationLanguages"
                    (click)="switchLanguage(language, $event)"
                    class="language-option">{{language.name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
</nav>