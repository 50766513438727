<div class="container">
    <div class="footer-row">
        <div class="footer-col large">
            <h4 class="copyright" translate [translateParams]="{now:year}">COPYRIGHT</h4>
        </div>
        <div class="footer-col">
            <h4 translate>FOOTER_CATEGORY_LEGAL</h4>
            <ul class="">
                <li>
                    <a href="https://www.gastivo.{{tld}}/{{staticPagePathPrefix}}impressum" translate>IMPRINT</a>
                </li>
                <li>
                    <a href="https://www.gastivo.{{tld}}/{{staticPagePathPrefix}}agb" translate>TERMS</a>
                </li>
                <li>
                    <a href="https://www.gastivo.{{tld}}/{{staticPagePathPrefix}}datenschutz" translate>DATA_TERMS</a>
                </li>
            </ul>
        </div>
        <div class="footer-col">
            <h4 translate>FOOTER_CATEGORY_CONTACT</h4>
            <ul class="">
                <li *ngIf="isDE">
                    <a href="mailto:{{'SUPPORT_MAIL'|translate}}" translate>SUPPORT_MAIL</a>
                </li>
                <li *ngIf="isDE">
                    <a href="tel:{{'SUPPORT_TELEFONE'|translate}}" translate>SUPPORT_TELEFONE</a>
                </li>
                <li>
                    <a href="https://www.gastivo.{{tld}}/kontakt" target="_blank" translate>FOOTER_CONTACT</a>
                </li>
                <li>
                    <a href="https://www.gastivo.{{tld}}/faq" target="_blank" translate>FOOTER_FAQ</a>
                </li>
                <li *ngIf="isDE">
                    <a href="https://www.gastivo.{{tld}}/newsletter" target="_blank" translate>FOOTER_NEWSLETTER</a>
                </li>
            </ul>
        </div>
    </div>
</div>