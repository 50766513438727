/**
 * Userware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Salutation = 'HERR' | 'FRAU';

export const Salutation = {
    HERR: 'HERR' as Salutation,
    FRAU: 'FRAU' as Salutation
};

