import { Article, Solution, Category, Tag, ImageUrls } from '../../client-content/index';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface SafeImages {
    thumbnail?: SafeResourceUrl;

    medium?: SafeResourceUrl;

    mediumLarge?: SafeResourceUrl;

    large?: SafeResourceUrl;

    full?: SafeResourceUrl;

    caption?: SafeResourceUrl;
}

abstract class AbstractDisplayContent {
    id?: string;

    lastUpdate?: Date;

    creationDate?: Date;

    title?: string;

    excerpt?: string;

    slug?: string;

    content?: string;

    cmsLastUpdate?: Date;

    categories?: Array<Category>;

    tags?: Array<Tag>;

    images?: ImageUrls;

    safeImages?: SafeImages = {};

    published?: boolean;

    safeContent?: any;

    srcset?: string = "";

    srcsetThumbnail?: string = "";

    constructor(article: any, domSanitizer: DomSanitizer) {
        if (article) {
            for (let attribute in article) {
                this[attribute] = article[attribute];
            }

            this.safeContent = domSanitizer.bypassSecurityTrustHtml(this.content);
            if (this.images) {
                for (let image in this.images) {
                    if (this.images[image] && this.images[image].url) {
                        this.safeImages[image] = domSanitizer.bypassSecurityTrustResourceUrl(this.images[image].url);
                        let s = this.images[image].url + " " + this.images[image].width + "w, ";
                        this.srcset += s;
                        if (image.indexOf('thumbnail') > -1) {
                            this.srcsetThumbnail += s;
                        }
                    }
                }
                this.srcset = this.srcset.substr(0, this.srcset.length - 1);
                this.srcsetThumbnail = this.srcsetThumbnail.substr(0, this.srcsetThumbnail.length - 1);
            }
        }
    }
}

export class DisplayArticle extends AbstractDisplayContent {


    campaignId?: string;

    productLinks?: Array<string>;

    solutions?: Array<Solution>;

    relatedArticleIds?: Array<string>;

    constructor(article: Article, domSanitizer: DomSanitizer) {
        super(article, domSanitizer);
    }

}

export class DisplaySolution extends AbstractDisplayContent {

    internalUrl?: string;

    solutionwareId?: string;

    isComingSoon: boolean;

    link: string;

    isSolution: boolean = true;

    constructor(solution: Solution, domSanitizer: DomSanitizer) {
        super(solution, domSanitizer);
        if (this.categories) {
            for (let i = 0; i < this.categories.length; i++) {
                if (this.isComingSoon = this.categories[i].slug == 'coming-soon')
                    break;
            }
        }

        if (solution.internalUrl) {
            this.link = solution.internalUrl;
        } else {
            this.link = "/loesungen/" + solution.slug;
        }
    }
}

export class WeFormGroup extends FormGroup {
    public sent: boolean = false;
    public loading: boolean = false;
}

export interface SelectOption {
    label: string;
    value: any;
}

export interface Product {
    articleID: number;
    highlight: string;
    description: string;
    description_long: string;
    articleName: string;
    tax: number;
    priceStartingFrom: string;
    price: string;
    pseudoprice: string;
    referenceprice: string;
    has_pseudoprice: boolean;
    price_numeric: number;
    pseudoprice_numeric: number;
    linkBasket: string;
    linkDetails: string;
    linkVariant: string;
    image_srcset: string;
    image_description: string;
}