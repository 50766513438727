export enum Environment {
    DE_DEV = 'DE_DEV',
    DE_STAGE = 'DE_STAGE',
    DE_UAT = 'DE_UAT',
    DE_PROD = 'DE_PROD',
}

export const EnvironmentMap: Map<RegExp, Environment> = new Map([
    [/localhost:\d+$/, Environment.DE_DEV],
    [/easy-de\.gastivo\.info$/, Environment.DE_STAGE],
    [/gastivo\.biz$/, Environment.DE_UAT],
]);
