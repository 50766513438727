import { Component } from '@angular/core';
import { ApplicationLanguage } from '../location/applicationLanguage';
import { LocationService } from '../location/location.service';

/**
 * This component renders the main navigation
 */
@Component({
    selector: 'main-navigation',
    templateUrl: 'main-navigation.html'
})
export class MainNavigationComponent{

    applicationLanguages: Array<ApplicationLanguage>;
    currentApplicationLanguage: ApplicationLanguage;
    showLanguageSelection: boolean = false;

    /**
     * The component's constructor
     */
    constructor(public locationService: LocationService) {
    }

    ngAfterContentInit() {
      this.applicationLanguages = this.locationService.getAvailableApplicationLanguages();
        this.currentApplicationLanguage = this.locationService.getCurrentLanguage();
        this.locationService.onLanguageSwitch(() => {
            this.currentApplicationLanguage = this.locationService.getCurrentLanguage();
        })
    }

    switchLanguage(selectedLanguage: ApplicationLanguage, $event: any) {
      $event.preventDefault();
      $event.stopPropagation();
      this.locationService.switchLanguage(selectedLanguage.isoCode);
      this.currentApplicationLanguage = selectedLanguage;
      this.showLanguageSelection = !this.showLanguageSelection;
    }

    openLanguageSelector() {
      this.showLanguageSelection = !this.showLanguageSelection;
    }

}
