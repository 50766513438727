/**
 * Userware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Business } from '../model/business';
// @ts-ignore
import { BusinessUnit } from '../model/businessUnit';
// @ts-ignore
import { Customer } from '../model/customer';
// @ts-ignore
import { LegalAgreement } from '../model/legalAgreement';
// @ts-ignore
import { LoginToken } from '../model/loginToken';
// @ts-ignore
import { SessionInfo } from '../model/sessionInfo';
// @ts-ignore
import { Supplier } from '../model/supplier';
// @ts-ignore
import { SystemRole } from '../model/systemRole';
// @ts-ignore
import { User } from '../model/user';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AdminService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get a businesses for the current users customer. Requires BUSINESS_MANAGER role.
     * @param businessId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminCRMFullUpdateBusiness(businessId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public adminCRMFullUpdateBusiness(businessId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public adminCRMFullUpdateBusiness(businessId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public adminCRMFullUpdateBusiness(businessId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling adminCRMFullUpdateBusiness.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminCRMFullUpdateBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/crm-update`;
        return this.httpClient.request<Business>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a supplieres for the current users customer. Requires BUSINESS_MANAGER role.
     * @param supplierId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminCRMFullUpdateSupplier(supplierId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Supplier>;
    public adminCRMFullUpdateSupplier(supplierId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Supplier>>;
    public adminCRMFullUpdateSupplier(supplierId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Supplier>>;
    public adminCRMFullUpdateSupplier(supplierId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling adminCRMFullUpdateSupplier.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminCRMFullUpdateSupplier.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/supplier/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/crm-update`;
        return this.httpClient.request<Supplier>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Force CRM update for this user. Requires USER_ADMIN role
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminCRMUpdateUser(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public adminCRMUpdateUser(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public adminCRMUpdateUser(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public adminCRMUpdateUser(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling adminCRMUpdateUser.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminCRMUpdateUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/crm-update`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param gstvSession The user session id
     * @param legalAgreement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminCreateLegalAgreement(gstvSession: string, legalAgreement: LegalAgreement, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<LegalAgreement>;
    public adminCreateLegalAgreement(gstvSession: string, legalAgreement: LegalAgreement, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<LegalAgreement>>;
    public adminCreateLegalAgreement(gstvSession: string, legalAgreement: LegalAgreement, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<LegalAgreement>>;
    public adminCreateLegalAgreement(gstvSession: string, legalAgreement: LegalAgreement, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminCreateLegalAgreement.');
        }
        if (legalAgreement === null || legalAgreement === undefined) {
            throw new Error('Required parameter legalAgreement was null or undefined when calling adminCreateLegalAgreement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/legal-agreement`;
        return this.httpClient.request<LegalAgreement>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: legalAgreement,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a legal agreement. Requires USER_ADMIN role.
     * @param legalAgreementId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminDeleteLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<LegalAgreement>;
    public adminDeleteLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<LegalAgreement>>;
    public adminDeleteLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<LegalAgreement>>;
    public adminDeleteLegalAgreement(legalAgreementId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (legalAgreementId === null || legalAgreementId === undefined) {
            throw new Error('Required parameter legalAgreementId was null or undefined when calling adminDeleteLegalAgreement.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminDeleteLegalAgreement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/legal-agreement/${this.configuration.encodeParam({name: "legalAgreementId", value: legalAgreementId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<LegalAgreement>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete failed login information from given user. Requires USER_ADMIN role.
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminDeleteUserFailedLogins(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any>;
    public adminDeleteUserFailedLogins(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<any>>;
    public adminDeleteUserFailedLogins(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<any>>;
    public adminDeleteUserFailedLogins(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling adminDeleteUserFailedLogins.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminDeleteUserFailedLogins.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/failed-logins`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a businesses for the current users customer. Requires BUSINESS_MANAGER role.
     * @param businessId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetBusinessById(businessId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public adminGetBusinessById(businessId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public adminGetBusinessById(businessId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public adminGetBusinessById(businessId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling adminGetBusinessById.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminGetBusinessById.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Business>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a legal agreement. Requires USER_ADMIN role
     * @param legalAgreementId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<LegalAgreement>;
    public adminGetLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<LegalAgreement>>;
    public adminGetLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<LegalAgreement>>;
    public adminGetLegalAgreement(legalAgreementId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (legalAgreementId === null || legalAgreementId === undefined) {
            throw new Error('Required parameter legalAgreementId was null or undefined when calling adminGetLegalAgreement.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminGetLegalAgreement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/legal-agreement/${this.configuration.encodeParam({name: "legalAgreementId", value: legalAgreementId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<LegalAgreement>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all customers. Requires USER_ADMIN role.
     * @param customerId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetMasterBusiness(customerId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public adminGetMasterBusiness(customerId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public adminGetMasterBusiness(customerId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public adminGetMasterBusiness(customerId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling adminGetMasterBusiness.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminGetMasterBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/customer/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/master`;
        return this.httpClient.request<Business>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a supplieres for the current users customer. Requires BUSINESS_MANAGER role.
     * @param supplierId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetSupplierById(supplierId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Supplier>;
    public adminGetSupplierById(supplierId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Supplier>>;
    public adminGetSupplierById(supplierId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Supplier>>;
    public adminGetSupplierById(supplierId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling adminGetSupplierById.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminGetSupplierById.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/supplier/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Supplier>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user. Requires USER_ADMIN role
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetUser(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public adminGetUser(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public adminGetUser(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public adminGetUser(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling adminGetUser.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminGetUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the link from the user\&#39;s accept-invite-email if any. Requires USER_ADMIN role
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetUserAcceptInviteLink(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<string>;
    public adminGetUserAcceptInviteLink(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<string>>;
    public adminGetUserAcceptInviteLink(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<string>>;
    public adminGetUserAcceptInviteLink(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling adminGetUserAcceptInviteLink.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminGetUserAcceptInviteLink.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accept-invite-link`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all customers. Requires USER_ADMIN role.
     * @param gstvSession The user session id
     * @param q Search term for business name
     * @param customerId Return only users associated with this customer
     * @param master Return only master accounts
     * @param limit Max number of returned businesses
     * @param offset Offset
     * @param businessUnit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminListBusinesses(gstvSession: string, q?: string, customerId?: string, master?: boolean, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<Business>>;
    public adminListBusinesses(gstvSession: string, q?: string, customerId?: string, master?: boolean, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<Business>>>;
    public adminListBusinesses(gstvSession: string, q?: string, customerId?: string, master?: boolean, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<Business>>>;
    public adminListBusinesses(gstvSession: string, q?: string, customerId?: string, master?: boolean, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminListBusinesses.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (master !== undefined && master !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>master, 'master');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (businessUnit !== undefined && businessUnit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>businessUnit, 'businessUnit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/businesses`;
        return this.httpClient.request<Array<Business>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all customers. Requires USER_ADMIN role.
     * @param gstvSession The user session id
     * @param q Search term for customer name
     * @param limit Max number of returned users
     * @param offset Offset of returned users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminListCustomers(gstvSession: string, q?: string, limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<Customer>>;
    public adminListCustomers(gstvSession: string, q?: string, limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<Customer>>>;
    public adminListCustomers(gstvSession: string, q?: string, limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<Customer>>>;
    public adminListCustomers(gstvSession: string, q?: string, limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminListCustomers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/customers`;
        return this.httpClient.request<Array<Customer>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all legal agreements. Requires USER_ADMIN role.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminListLegalAgreements(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<LegalAgreement>>;
    public adminListLegalAgreements(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<LegalAgreement>>>;
    public adminListLegalAgreements(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<LegalAgreement>>>;
    public adminListLegalAgreements(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminListLegalAgreements.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/legal-agreements`;
        return this.httpClient.request<Array<LegalAgreement>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all customers. Requires USER_ADMIN role.
     * @param gstvSession The user session id
     * @param q Search term for supplier name
     * @param limit Max number of returned supplieres
     * @param offset Offset
     * @param businessUnit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminListSuppliers(gstvSession: string, q?: string, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<Supplier>>;
    public adminListSuppliers(gstvSession: string, q?: string, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<Supplier>>>;
    public adminListSuppliers(gstvSession: string, q?: string, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<Supplier>>>;
    public adminListSuppliers(gstvSession: string, q?: string, limit?: number, offset?: number, businessUnit?: BusinessUnit, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminListSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (businessUnit !== undefined && businessUnit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>businessUnit, 'businessUnit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/supplieres`;
        return this.httpClient.request<Array<Supplier>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all users. Requires USER_ADMIN role.
     * @param gstvSession The user session id
     * @param q Search term for user name
     * @param customerId Return only users associated with this customer
     * @param businessId Return only users associated with this business
     * @param limit Max number of returned users
     * @param offset Offset of returned users
     * @param includeRoles Include roles
     * @param businessUnit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminListUsers(gstvSession: string, q?: string, customerId?: string, businessId?: string, limit?: number, offset?: number, includeRoles?: boolean, businessUnit?: BusinessUnit, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<User>>;
    public adminListUsers(gstvSession: string, q?: string, customerId?: string, businessId?: string, limit?: number, offset?: number, includeRoles?: boolean, businessUnit?: BusinessUnit, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<User>>>;
    public adminListUsers(gstvSession: string, q?: string, customerId?: string, businessId?: string, limit?: number, offset?: number, includeRoles?: boolean, businessUnit?: BusinessUnit, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<User>>>;
    public adminListUsers(gstvSession: string, q?: string, customerId?: string, businessId?: string, limit?: number, offset?: number, includeRoles?: boolean, businessUnit?: BusinessUnit, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminListUsers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (businessId !== undefined && businessId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>businessId, 'businessId');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (includeRoles !== undefined && includeRoles !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeRoles, 'includeRoles');
        }
        if (businessUnit !== undefined && businessUnit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>businessUnit, 'businessUnit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/users`;
        return this.httpClient.request<Array<User>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all users in CSV format. Requires USER_ADMIN role.
     * @param gstvSession The user session id
     * @param q Search term for user name
     * @param businessUnit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminListUsersCsv(gstvSession: string, q?: string, businessUnit?: BusinessUnit, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<any>;
    public adminListUsersCsv(gstvSession: string, q?: string, businessUnit?: BusinessUnit, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<HttpResponse<any>>;
    public adminListUsersCsv(gstvSession: string, q?: string, businessUnit?: BusinessUnit, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<HttpEvent<any>>;
    public adminListUsersCsv(gstvSession: string, q?: string, businessUnit?: BusinessUnit, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminListUsersCsv.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (businessUnit !== undefined && businessUnit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>businessUnit, 'businessUnit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/users-csv`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a legal agreement (Requires USER_ADMIN role)
     * @param legalAgreementId 
     * @param gstvSession The user session id
     * @param legalAgreement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUpdateLegalAgreement(legalAgreementId: string, gstvSession: string, legalAgreement: LegalAgreement, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<LegalAgreement>;
    public adminUpdateLegalAgreement(legalAgreementId: string, gstvSession: string, legalAgreement: LegalAgreement, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<LegalAgreement>>;
    public adminUpdateLegalAgreement(legalAgreementId: string, gstvSession: string, legalAgreement: LegalAgreement, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<LegalAgreement>>;
    public adminUpdateLegalAgreement(legalAgreementId: string, gstvSession: string, legalAgreement: LegalAgreement, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (legalAgreementId === null || legalAgreementId === undefined) {
            throw new Error('Required parameter legalAgreementId was null or undefined when calling adminUpdateLegalAgreement.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminUpdateLegalAgreement.');
        }
        if (legalAgreement === null || legalAgreement === undefined) {
            throw new Error('Required parameter legalAgreement was null or undefined when calling adminUpdateLegalAgreement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/legal-agreement/${this.configuration.encodeParam({name: "legalAgreementId", value: legalAgreementId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<LegalAgreement>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: legalAgreement,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a user (Requires USER_ADMIN role)
     * @param userId 
     * @param gstvSession The user session id
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUpdateUser(userId: string, gstvSession: string, user: User, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public adminUpdateUser(userId: string, gstvSession: string, user: User, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public adminUpdateUser(userId: string, gstvSession: string, user: User, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public adminUpdateUser(userId: string, gstvSession: string, user: User, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling adminUpdateUser.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminUpdateUser.');
        }
        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling adminUpdateUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: user,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userId 
     * @param gstvSession The user session id
     * @param systemRole 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUpdateUserSystemRoles(userId: string, gstvSession: string, systemRole: Array<SystemRole>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public adminUpdateUserSystemRoles(userId: string, gstvSession: string, systemRole: Array<SystemRole>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public adminUpdateUserSystemRoles(userId: string, gstvSession: string, systemRole: Array<SystemRole>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public adminUpdateUserSystemRoles(userId: string, gstvSession: string, systemRole: Array<SystemRole>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling adminUpdateUserSystemRoles.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling adminUpdateUserSystemRoles.');
        }
        if (systemRole === null || systemRole === undefined) {
            throw new Error('Required parameter systemRole was null or undefined when calling adminUpdateUserSystemRoles.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user-roles/system/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: systemRole,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a login token for the user. (Requires USER_ADMIN role)
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLoginToken(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<LoginToken>;
    public createLoginToken(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<LoginToken>>;
    public createLoginToken(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<LoginToken>>;
    public createLoginToken(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling createLoginToken.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling createLoginToken.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/login-token`;
        return this.httpClient.request<LoginToken>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user. Requires USER_ADMIN role
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSessionInfo(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<SessionInfo>>;
    public getSessionInfo(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<SessionInfo>>>;
    public getSessionInfo(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<SessionInfo>>>;
    public getSessionInfo(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getSessionInfo.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getSessionInfo.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/sessoin`;
        return this.httpClient.request<Array<SessionInfo>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
