
import { enableProdMode } from '@angular/core';
let base = 'gastivo.de';
let config = {
    contentApiPath: 'https://api.' + base + '/content',
    shopApiPath: 'https://api.' + base + '/shop',
    userApiPath: 'https://api.' + base + '/user',
    solutionApiPath: 'https://api.' + base + '/solution',
    applicationName: '',
    cookieDomain: base,
    userFailtrak: true,
    failtrakPath: 'https://ft.gastivo.de',
    gtmId: {
        DE: 'GTM-5HBBRRK',
        CH: 'GTM-KV26TN7',
    },
    dev: false
}
if (process.env.ENV == 'develop') {
    let hostname = location.hostname;
    config = {
        ...config,
        contentApiPath: 'http://' + hostname + ':8083',
        shopApiPath: 'http://' + hostname + ':8085',
        userApiPath: 'http://' + hostname + ':8087',
        solutionApiPath: 'http://' + hostname + ':8081',
        applicationName: '',
        cookieDomain: '' + hostname + '',
        userFailtrak: false,
        failtrakPath: 'http://' + hostname + ':8090',
        dev: true
    }
} else {
    enableProdMode();
}
export const Config = config;