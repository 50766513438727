import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

export class FlashMessage {
    text: string;
    type: "info" | "success" | "warning" | "danger";
    timer: number;

    constructor(text: string, type: "info" | "success" | "warning" | "danger") {
        this.text = text;
        this.type = type;
    }
}

@Injectable()
export class FlashService {

    constructor(private translateService: TranslateService) {
        
    }

    private listeners: Function[] = [];

    public listenToMessageAdded(func: (message: FlashMessage) => void) {
        this.listeners.push(func);
    }

    public addMessage(text: string, type?: "info" | "success" | "warning" | "danger", translateParams?: {[key:string]:string}) {
        if (!type) {
            type = 'success';
        }
        this.translateService.get(text, translateParams).subscribe((res: string) => {
            let message = new FlashMessage(res, type);
            for (var i = 0; i < this.listeners.length; i++) {
                this.listeners[i](message);
            }    
        })
        
    }
}