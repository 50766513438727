export * from './flash.component';
export * from './flash.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { FlashMessageComponent } from './flash.component';
import { FlashMessage, FlashService } from './flash.service';

@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    exports: [FlashMessageComponent],
    declarations: [FlashMessageComponent],
    providers: [FlashService],
})
export class FlashModule { }
