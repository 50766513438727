<section class="easy-registration-start">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-10 col-md-offset-1">
                <h1 [innerHtml]="'START_TITLE'|translate"></h1>
                <p class="subline" translate>START_TEXT</p>
                <form class="form-regular" (submit)="loadCode()">
                    <div class="form-group">
                        <input type="text" name="code" [(ngModel)]="code" id="" class="form-control" [placeholder]="'START_CODE_PLACEHOLDER'|translate">
                    </div>
                    <button style="position: relative; z-index: 5000;" type="submit" [ngClass]="{'disabled':!code}" class="btn btn-primary" translate>START_CODE_GO</button>
                </form>
            </div>
        </div>
    </div>
</section>