<section class="easy-registration declined register-success center" [ngClass]="{'done':registrationDeclined}">
    <div class="container-fluid" *ngIf="ready">
        <div class="content" [ngClass]="{'loading white': registrationForm?.loading}">
            <div *ngIf="registrationDeclined">
              <img [src]="supplierLogo" [alt]="supplierName" class="supplier-logo">
              <h1 [innerHtml]="'REGISTER_DECLINED_TITLE'|translate"></h1>
              <p><span [innerHtml]="'REGISTER_DECLINED_TEXT'|translate"></span>&nbsp;
              <span [innerHtml]="'REGISTER_DECLINED_FOOT'|translate:{name:supplierName}"></span></p>
              <div class="text-banner result-foot"*ngIf="!isDomainCH()" [innerHTML]="'TEXT_BANNER_DECLINED' | translate"></div>
            </div>
            <div *ngIf="codeNotFound" [innerHtml]="'REGISTRATION_CODE_NOT_FOUND'|translate"></div>
            <div *ngIf="codeAlreadyUsed" [innerHtml]="'REGISTRATION_CODE_ALREADY_USED'|translate"></div>
        </div>
    </div>
    <div class="loading white" *ngIf="!ready"></div>
</section>