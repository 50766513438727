import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StaticPageComponent } from './static-page/static-page.component';
import { StartComponent } from "./start/start.component";
import { EasyRegistrationComponent } from "./easy-registration/easy-registration.component";
import { DeclineComponent } from './decline/decline.component';

const routes: Routes = [

    {
        path: 's/:slug', component: StaticPageComponent, data: { noRedirect: true }
    },
    {
        path: 'go/:code', component: EasyRegistrationComponent, data: { noRedirect: true }
    },
    {
        path: 'decline/:code', component: DeclineComponent, data: { noRedirect: true }
    },
    {
        path: '', component: StartComponent, data: { noRedirect: true }
    },
    {
        path: 'agb', redirectTo: 's/agb', pathMatch: 'full'
    },
    {
        path: 'datenschutz', redirectTo: 's/datenschutz', pathMatch: 'full'
    },
    {
        path: '**', redirectTo: '', pathMatch: 'full'
    }
];

let noRedirectFor: string[] = [];

for (var i = 0; i < routes.length; i++) {
    var element = routes[i];
    if (element.data && element.data['noRedirect']) {
        let p = element.path.split(":");
        noRedirectFor.push(p[0]);
    }
}

export const noRedirect = noRedirectFor;

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes);

export const routedComponents = [''];