import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PagesService, StaticPage } from '../../../client-content/index';
import { MetadataService } from '../services/metadata.service';
import { ErrorService } from '../services/error-handle.service';
import { SupplierInfoService } from "../services/supplier-info.service";
import { TranslateService } from "@ngx-translate/core";
import 'jquery';
import { LocationService } from '../location/location.service';

@Component({
    selector: 'static-page',
    templateUrl: 'static-page.html'
})
export class StaticPageComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    page: StaticPage;
    content: SafeHtml;
    title: SafeHtml;
    loading: boolean = false;
    isCH: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router, private pagesApi: PagesService, private domSanitizer: DomSanitizer, private metadataService: MetadataService, private errorService: ErrorService, private translateService: TranslateService, private supplierInfoService: SupplierInfoService, private locationService: LocationService) {
        this.metadataService.setTag('robots', 'INDEX,FOLLOW');
        this.isCH = locationService.isDomainCH()
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe(
            (param: any) => {
                this.loading = true;
                let slug = param['slug'];
                if (this.redirectSlug(slug)) {
                  return
                }
                this.supplierInfoService.subscribe(supplierName => {
                    if (!supplierName) {
                        this.translateService.get('DEFAULT_SUPPLIER_TEXT').subscribe(res => {
                            supplierName = res;
                            this.loadPage(slug, supplierName);
                        })
                    } else {
                        this.loadPage(slug, supplierName);
                    }
                })
            });
    }

    private redirectSlug(slug: string): boolean {
      let redirect = null;
      if (this.isCH) {
        switch (slug) {
          case 'agb':
            redirect = 'https://www.gastivo.ch/agb'
            break;
            case 'datenschutz':
              redirect = 'https://www.gastivo.ch/datenschutz'
              break;
        }
      }
      if (redirect) {
        window.location.replace(redirect)
        return true
      }
      return false
    }

    loadPage(slug: string, supplierName: string) {
        this.pagesApi.getStaticPageBySlug(slug).subscribe((data) => {
            if (data) {
                this.page = data;
                this.metadataService.setTitle(data.title);
                this.metadataService.setMetaDescription(data.excerpt);
                let content = this.page.content.replace(/SUPPLIER_NAME/g, supplierName);
                this.content = this.domSanitizer.bypassSecurityTrustHtml(content);
                this.title = this.domSanitizer.bypassSecurityTrustHtml(this.page.title);
                this.loading = false;
            } else {
                this.router.navigate(['/']);
            }
        }, this.errorService.buildSimpleHandler({

            statusHandles: {
                404: () => {
                    this.router.navigate(['/']);
                }
            }
        }));
    }

    ngOnDestroy() {
        // prevent memory leak by unsubscribing
        this.subscription.unsubscribe();
    }


}
